import groq from 'groq'
import { linkFragment } from './link'

export const imageFragment = groq`
  ...,
  asset->,
  link {
    ${linkFragment}
  },
`

export const imageAssetBySourceQuery = groq`
  *[
    _type == "sanity.imageAsset" &&
    source.name == $sourceName &&
    source.id == $sourceId
  ][0]
`

export const imageAssetsBySourceQuery = groq`
  *[
    _type == "sanity.imageAsset" &&
    source.name == $sourceName &&
    source.id in $sourceIds
  ]
`
