import builder from '@sanity/image-url'
import {
  type ImageFormat,
  type SanityImageSource,
} from '@sanity/image-url/lib/types/types'
import { type ImageLoader } from 'next/image'

import {
  type SanityImageFragment,
  type SanityImageFragmentWithContent,
} from '@data/sanity/queries/types/image'
import { getSanityClient } from './sanity/client'
import { parseJson } from './helpers'

interface SourceOptionsProps {
  width?: number
  height?: number
  format?: ImageFormat
  quality?: number
  fitMax?: boolean
  autoFormat?: boolean
}

export type PhotoImage = SanityImageFragment | SanityImageFragmentWithContent

export interface ImageDimensions {
  width: number
  height: number
}

/**
 * Gets image loader for Next.js image component.
 */
export const getSanityImageLoader: ImageLoader = ({ src, width, quality }) => {
  const parsedSource = parseJson(src)
  const image = parsedSource?.asset
    ? (parsedSource as unknown as SanityImageFragment)
    : { asset: src.includes('://') ? { url: src } : { _ref: src } }
  const options: SourceOptionsProps = {
    width,
    quality,
    fitMax: true,
    autoFormat: true,
  }

  // Calculate image height
  const aspectRatio =
    'customRatio' in image && image.customRatio
      ? image.customRatio
      : 'metadata' in image.asset &&
        image.asset?.metadata?.dimensions?.aspectRatio

  if (aspectRatio) {
    options.height = width / aspectRatio
  }

  return getSanityImageUrl(image, options) ?? ''
}

/**
 * Builds image source URL from image data and options.
 */
export function getSanityImageUrl(
  image?: SanityImageSource,
  {
    width,
    height,
    format,
    quality,
    fitMax,
    autoFormat,
  }: SourceOptionsProps = {},
) {
  if (!image) {
    return
  }

  try {
    const sanityClient = getSanityClient()
    let imageUrlBuilder = builder(sanityClient).image(image)

    if (width) {
      imageUrlBuilder = imageUrlBuilder.width(Math.round(width))
    }

    if (height) {
      imageUrlBuilder = imageUrlBuilder.height(Math.round(height))
    }

    if (format) {
      imageUrlBuilder = imageUrlBuilder.format(format)
    }

    if (quality) {
      imageUrlBuilder = imageUrlBuilder.quality(quality)
    }

    if (fitMax) {
      imageUrlBuilder = imageUrlBuilder.fit('max')
    }

    if (autoFormat) {
      imageUrlBuilder = imageUrlBuilder.auto('format')
    }

    return imageUrlBuilder.url()
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gets scalar value from any value.
 */
export const getScalarValue = (value?: string) => {
  const scalarValue = value?.match(/-?[\d.]+/g)?.join('')

  if (!scalarValue) {
    return
  }

  return Number(scalarValue)
}

/**
 * Extracts CSS unit from string value
 */
export function getCSSUnit(value?: string) {
  return value
    ?.match(/(%|px|rem|em|vh|vw)/gi)
    ?.filter(Boolean)
    ?.join('')
}

/**
 * Gets fixed width CSS rule from fixed height CSS rule and aspect ratio.
 */
export const getFixedWidth = (aspectRatio: number, fixedHeight?: string) => {
  const heightValue = getScalarValue(fixedHeight)

  if (!heightValue) {
    return
  }

  const heightUnit = getCSSUnit(fixedHeight)

  return `${heightValue / aspectRatio}${heightUnit || 'px'}`
}

/**
 * Gets imgae source from image data.
 */
export const getImageSource = (image: PhotoImage) => {
  // Encode image content to base 64
  if ('content' in image) {
    return `data:${image.asset?.mimeType};base64,${Buffer.from(
      image.content,
      'utf-8',
    ).toString('base64')}`
  }

  return JSON.stringify(image)
}

/**
 * Gets image dimensions from one of the following sources:
 * - component props,
 * - fixed height field,
 * - image metadata dimensions and custom aspect ratio field,
 * - image metadata dimensions
 */
export const getImageDimensions = (
  image: SanityImageFragment,
  width?: number,
  height?: number,
) => {
  // Component props
  if (typeof width !== 'undefined' && typeof height !== 'undefined') {
    return {
      width,
      height,
    }
  }

  // Fixed height field
  const fixedHeight = getScalarValue(image.fixedHeight)
  const fixedHeightUnit = getCSSUnit(image.fixedHeight)

  if (fixedHeight) {
    const fixedHeightUnitScale = fixedHeightUnit === 'rem' ? 16 : 1
    const fixedHeightAspectRatio =
      image.customRatio || image.asset.metadata.dimensions.aspectRatio

    return {
      width: fixedHeight * fixedHeightAspectRatio * fixedHeightUnitScale,
      height: fixedHeight * fixedHeightUnitScale,
    }
  }

  // Image metadata dimensions and custom aspect ratio field
  if (
    image.asset?.metadata?.dimensions &&
    image.customRatio &&
    image.customRatio.toFixed(2) !==
      image.asset.metadata.dimensions.aspectRatio.toFixed(2)
  ) {
    if (image.customRatio > image.asset.metadata.dimensions.aspectRatio) {
      return {
        width: image.asset.metadata.dimensions.width,
        height: image.asset.metadata.dimensions.width / image.customRatio,
      }
    }

    return {
      width: image.asset.metadata.dimensions.height * image.customRatio,
      height: image.asset.metadata.dimensions.height,
    }
  }

  // Image metadata dimensions
  if (image.asset?.metadata?.dimensions) {
    return {
      width: image.asset.metadata.dimensions.width,
      height: image.asset.metadata.dimensions.height,
    }
  }
}

/**
 * Gets image loader for Next.js image component.
 */
export const getBasicImageLoader: ImageLoader = ({ src }) => {
  return src
}
