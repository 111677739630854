import groq from 'groq'

export const linkPageFragment = groq`
  _id,
  _type,
  slug,
`

export const linkFragment = groq`
  ...,
  page->{
    ${linkPageFragment}
  },
`
