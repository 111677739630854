import { useContext, useState } from 'react'
import cx from 'classnames'

import { type SanityCartSettings } from '@data/sanity/queries/types/cart'
import { CartContext } from '@lib/cart/context'
import { type ErrorMessages } from '@lib/helpers'

import CartItem from '@blocks/shop/cart-item'
import CommentField from './comment-field'
import CartEmpty from './empty'
import CartSubmit from './submit'
import CartSummary from './summary'
import VatIdField from './vat-id-field'
import Photo from '@components/photo'
import CartToggle from '@components/cart-toggle'
import { StringsContext } from '@lib/strings-context'
import { useCartItemCount } from '@lib/cart/hooks'
import Icon from '@components/icon'

interface CartFormProps {
  id?: string
  cartSettings?: SanityCartSettings
  hasCartToggle?: boolean
  className?: string
  onClick?: () => void
}

const CartForm = ({
  id,
  cartSettings,
  hasCartToggle,
  className,
  onClick,
}: CartFormProps) => {
  const strings = useContext(StringsContext)
  const { cart, isCartUpdating, isCartSubmitting } = useContext(CartContext)
  const cartItemCount = useCartItemCount()

  const [vatId, setVatId] = useState('')
  const [comment, setComment] = useState('')
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  const handleVatIdChange = (newValue: string) => setVatId(newValue)
  const handleCommentChange = (newValue: string) => setComment(newValue)

  return (
    <div
      className={cx(
        'flex flex-col relative h-full w-full',
        {
          'cursor-wait': isCartUpdating || isCartSubmitting,
        },
        className,
      )}
    >
      <div className="flex gap-x-2 items-center justify-between py-6 px-3 border-b border-gray">
        <h4 className="flex items-center">
          {strings.cartTitle} {cartItemCount > 0 ? `(${cartItemCount})` : ''}
        </h4>

        {hasCartToggle && (
          <CartToggle className="uppercase">
            <Icon id="close-cart" name="Cross" className="w-3.5 h-3.5" />
          </CartToggle>
        )}
      </div>

      <div className="flex-1 overflow-y-scroll pt-3 no-scrollbar">
        {cart.lineItems.length > 0 && (
          <>
            <div className="flex flex-col gap-y-6 px-3">
              {cart.lineItems.map((item) => (
                <CartItem key={item.lineId} item={item} />
              ))}
            </div>

            <div className="bg-pageBG sticky bottom-0">
              <CartSummary />
            </div>
          </>
        )}

        {cart.lineItems.length === 0 && <CartEmpty />}
      </div>

      {cart.lineItems.length > 0 && (
        <div>
          {cartSettings?.showVatId && (
            <VatIdField
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
              onChange={handleVatIdChange}
            />
          )}

          {cartSettings?.showComment && (
            <CommentField onChange={handleCommentChange} />
          )}

          <div className="flex flex-col gap-y-4 px-3 py-4 border-t border-gray">
            {cartSettings && (
              <CartSubmit
                id={id ? `${id}-submit` : ''}
                terms={cartSettings.terms}
                storeUrl={cartSettings.storeUrl}
                setErrorMessages={setErrorMessages}
                vatId={vatId}
                comment={comment}
                onClick={onClick}
              />
            )}

            {cartSettings?.message && (
              <p className="text-center text-sm">{cartSettings.message}</p>
            )}

            {!!cartSettings?.paymentProviders && (
              <div className="flex flex-wrap justify-center items-center gap-5">
                {cartSettings?.paymentProviders?.map((provider) => (
                  <Photo
                    key={provider._key}
                    image={provider}
                    showPlaceholder={false}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default CartForm
