import cx from 'classnames'
import { useContext } from 'react'

import { CartContext } from '@lib/cart/context'
import { useCartTotals } from '@lib/cart/hooks'
import { type AutomaticDiscount } from '@lib/cart/types'

import CartDiscount from './discount'
import CartSubtotal from './subtotal'
import CartTotal from './total'

interface CartSummaryProps {
  className?: string
}

const CartSummary = ({ className }: CartSummaryProps) => {
  const { subTotal, totalDiscount, total } = useCartTotals()
  const { cart } = useContext(CartContext)

  const automaticDiscount: AutomaticDiscount | null =
    cart?.automaticDiscount ?? null

  return (
    <div className={cx('text-xs uppercase', className)}>
      <CartSubtotal price={subTotal} className="border-t border-gray" />

      <div className="[&>*]:border-t [&>*]:border-gray-light [&>*:first-child]:border-gray">
        {!!automaticDiscount && automaticDiscount.amount > 0 && (
          <CartDiscount
            title={automaticDiscount.title}
            amount={automaticDiscount.amount}
          />
        )}
      </div>

      <CartTotal
        price={total - totalDiscount}
        className="border-t border-gray"
      />
    </div>
  )
}

export default CartSummary
