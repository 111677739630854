import { type PortableTextMarkComponentProps } from '@portabletext/react'
import cx from 'classnames'

import { type SanityBlockColoredText } from '@data/sanity/queries/types/content'

type ColoredTextValue = SanityBlockColoredText

const colorClasses: Record<string, string> = {}

const ColoredText = ({
  value,
  children,
}: PortableTextMarkComponentProps<ColoredTextValue>) => {
  if (!value) {
    return null
  }

  const {
    faded,
    //  color
  } = value

  return (
    <span
      className={cx(
        // color ? colorClasses[color] : '',
        {
          'opacity-30': !!value?.faded,
        },
      )}
    >
      {children}
    </span>
  )
}

export default ColoredText
