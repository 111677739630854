import { getLocaleVariable } from '@lib/environment'
import { parseRequiredParameter } from '@lib/helpers'
import { type Locale } from '@lib/language'
import { type ShopifyDataType, type ShopifyHeaders } from './types'

const shopifyApiVersion = '2024-04'

/**
 * Gets Shopify domain.
 */
export const getShopifyDomain = (locales: Locale[] | Locale) => {
  const locale = parseRequiredParameter<Locale>(locales)
  const storeId = getLocaleVariable(locale, 'NEXT_PUBLIC_SHOPIFY_STORE_ID')

  if (!storeId) {
    return
  }

  return `${storeId}.myshopify.com`
}

/**
 * Gets Shopify Storefront API URL.
 */
export const getShopifyStorefrontApiUrl = (shopifyDomain: string): string =>
  `https://${shopifyDomain}/api/${shopifyApiVersion}`

/**
 * Gets Shopify Admin API URL.
 */
export const getShopifyAdminApiUrl = (shopifyDomain: string): string =>
  `https://${shopifyDomain}/admin/api/${shopifyApiVersion}`

/**
 * Gets a Shopify global ID.
 */
export const getShopifyGlobalId = (
  type: ShopifyDataType,
  id: string | number,
) => `gid://shopify/${type}/${id}`

/**
 * Gets Shopify ID from Shopify global ID.
 */
export const parseShopifyGlobalId = (shopifyGlobalId: string) => {
  try {
    return Number(shopifyGlobalId.split('?')[0].split('/').slice(-1)[0])
  } catch (_) {
    return
  }
}

/**
 * Gets Shopify headers with access token.
 */
export const getShopifyHeaders = (
  locales: Locale | Locale[],
): ShopifyHeaders => {
  const locale = parseRequiredParameter<Locale>(locales)
  const apiPassword = getLocaleVariable(locale, 'SHOPIFY_API_PASSWORD')

  return {
    'Content-Type': 'application/json',
    'Accept-Encoding': 'gzip,deflate,compress',
    'X-Shopify-Access-Token': apiPassword ?? '',
  }
}
