import groq from 'groq'

export const muxVideoFragment = groq`
  muxVideo {
    asset->,
  },
`

export const videoFragment = groq`
  ...,
  ${muxVideoFragment}
`
