import cx from 'classnames'
import { useContext } from 'react'

import { getPrice } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface ProductPriceProps {
  price: number
  comparePrice?: number
  className?: string
}

const ProductPrice = ({
  price,
  comparePrice,
  className,
}: ProductPriceProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx('flex items-center gap-x-2 whitespace-nowrap', className)}
    >
      {!!comparePrice && (
        <span className="text-gray-dark line-through">{`${getPrice(comparePrice, taxRate)} ${currency ? currency : ''}`}</span>
      )}

      <span>{`${getPrice(price, taxRate)} ${currency ? currency : ''}`}</span>
    </div>
  )
}

export default ProductPrice
