import { type ReactNode, createContext, useMemo } from 'react'

import {
  type SanitySiteFragment,
  type SanityStrings,
} from '@data/sanity/queries/types/site'

type StringsContextProps = SanityStrings

const emptySiteStrings: StringsContextProps = {
  loadingPageTitle: '',
  languageSwitch: '',
  cartLink: '',
  skipToContent: '',
  goHomeLabel: '',
  mobileMenuLabel: '',
  searchLabel: '',
  shopTitle: '',
  searchResultProducts: '',
  searchResultProductCount: '',
  searchResultProductsNotFound: '',
  searchResultShowAllProducts: '',
  searchingProducts: '',
  collectionProducts: '',
  collectionProductCount: '',
  collectionSortLabel: '',
  collectionSortDescription: '',
  collectionShowFiltersLabel: '',
  collectionQuickFilterAllLabel: '',
  collectionFilters: '',
  collectionRemoveFilter: '',
  collectionClearAllLabel: '',
  collectionClearFiltersLabel: '',
  collectionFilterResultsLabel: '',
  collectionFilterNoResultsLabel: '',
  collectionFilterModalClose: '',
  collectionFilterNoResults: [],
  productLowStock: '',
  productOutOfStock: '',
  productPriceLabel: '',
  productColorOptionLabel: '',
  productDiscountText: '',
  productIncreaseQuantity: '',
  productDecreaseQuantity: '',
  productEnterQuantity: '',
  productRelatedProducts: '',
  cartTitle: '',
  cartClose: '',
  cartEmpty: '',
  cartVatSectionTitle: '',
  cartVatIdInputPlaceholder: '',
  cartVatIdError: '',
  cartInvalidVatIdError: '',
  cartCommentSectionTitle: '',
  cartCommentInputPlaceholder: '',
  cartDiscount: '',
  cartSubtotal: '',
  cartTotal: '',
  cartSubmit: '',
  cartAddToCartErrorMessage: [],
  carouselCounterText: '',
  carouselLeftArrowLabel: '',
  carouselRightArrowLabel: '',
  carouselDotLabel: '',
  buttonAddToCart: '',
  buttonRemove: '',
  buttonAccept: '',
  buttonLearnMore: '',
  buttonTryAgain: '',
  buttonLoadMore: '',
  buttonUnavailable: '',
  buttonWaiting: '',
  buttonLoading: '',
  buttonAdding: '',
  buttonUpdating: '',
  buttonSubmitting: '',
  buttonEdit: '',
  buttonDelete: '',
  buttonCancel: '',
  buttonShopNow: '',
  buttonBackTo: '',
  emailAddress: '',
  emailAddressPlaceholder: '',
  emailMissing: '',
  emailInvalid: '',
  firstName: '',
  firstNamePlaceholder: '',
  firstNameMissing: '',
  lastName: '',
  lastNamePlaceholder: '',
  lastNameMissing: '',
  fullName: '',
  fullNamePlaceholder: '',
  fullNameMissing: '',
  phoneNumber: '',
  phoneNumberPlaceholder: '',
  phoneNumberMissing: '',
  phoneNumberInvalid: '',
  company: '',
  companyPlaceholder: '',
  message: '',
  messagePlaceholder: '',
  productWaitlistSubmit: '',
  productWaitlistSuccess: '',
  productWaitlistError: '',
  searchPlaceholder: '',
  blogTitle: '',
  blogRelatedArticlesTitle: '',
  blogPostReadMore: '',
  blogPostByAuthor: '',
  teamMemberCardLinkedIn: '',
  teamMemberCardBlogArticles: '',
}

export const StringsContext =
  createContext<StringsContextProps>(emptySiteStrings)

interface StringsContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const StringsContextProvider = ({
  site,
  children,
}: StringsContextProviderProps) => {
  const strings = useMemo(
    () => ({
      ...emptySiteStrings,
      ...site.strings,
    }),
    [site.strings],
  )

  return (
    <StringsContext.Provider value={strings}>
      {children}
    </StringsContext.Provider>
  )
}
