interface RawHtmlProps {
  html: string
}

// This is a workaround that does what dangerouslySetInnerHTML on React.Fragment should.
// Source: https://github.com/reactjs/rfcs/pull/129#issuecomment-1140379292
const RawHtml = ({ html }: RawHtmlProps) => (
  <script
    dangerouslySetInnerHTML={{
      __html: `</script>${html}<script>`,
    }}
  />
)

export default RawHtml
