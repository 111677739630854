import axios from 'axios'
import { useCallback, useContext, useMemo } from 'react'
import useSWR from 'swr'

import {
  type SanityProductOption,
  type SanityProductOptionName,
  type SanityProductVariantFragment,
  type SanityProductFragment,
  type SanityProductOptionSetting,
  type SanityProductVariantOption,
} from '@data/sanity/queries/types/product'
import { compareNumbers, hasObject, parseOptionalParameter } from './helpers'
import { usePrevious, useUrlParameters } from './hooks'
import { type Locale } from './language'
import { type OptionValue } from './product-card'
import { SiteContext } from './site-context'

export interface Filter {
  name: string
  values: string[]
}

export interface FilterValue {
  name: string
  value: string
}

interface ProductInventoryRequest {
  localeHeader: Locale
  url: string
  id?: number
}

interface ProductInventoryVariant {
  id: number
  inStock: boolean
  lowStock: boolean
}

export interface ProductInventory {
  inStock: boolean
  lowStock: boolean
  variants: ProductInventoryVariant[]
}

export type ExpandedProduct = SanityProductFragment & {
  preferredOption?: OptionValue
}

export const productLowStockAmount = 10

export const productVariantLowStockAmount = 5

/**
 * Converts Sanity product or variant ID string into Shopify ID number.
 */
export const sanityProductIdToShopifyId = (sanityProductId: string) => {
  const shopifyProductId = sanityProductId.split('-')?.[1]

  if (!shopifyProductId) {
    return null
  }

  return Number(shopifyProductId)
}

/**
 * Gets product option label.
 */
export const getOptionLabel = (
  optionNames: SanityProductOptionName[],
  option: SanityProductOption,
) =>
  optionNames?.find(({ forOption }) => forOption === option.name)?.name ||
  option.name

/**
 * Gets default product option.
 */
export const getDefaultOption = (
  options: SanityProductOption[],
  optionSettings: SanityProductOptionSetting[],
): SanityProductVariantOption | undefined => {
  if (options.length === 0) {
    return
  }

  const firstOption = options?.[0]
  const defaultOption = {
    name: firstOption?.name,
    value: firstOption?.values?.[0],
    position: firstOption?.position,
  }

  if (optionSettings.length === 0) {
    return defaultOption
  }

  // Use first option setting to find default option
  const settingParts = optionSettings[0].forOption?.split(':')
  const name = settingParts?.[0]
  const value = settingParts?.[1]
  const position = options.find((option) => option.name === name)?.position

  if (
    typeof name === 'undefined' ||
    typeof value === 'undefined' ||
    typeof position === 'undefined'
  ) {
    return defaultOption
  }

  return { name, value, position }
}

/**
 * Gets a product variant by the default option.
 */
export const getVariantByDefaultOption = (
  variants: SanityProductVariantFragment[],
  defaultOption?: SanityProductVariantOption,
) => {
  if (!defaultOption) {
    return null
  }

  const variant = variants.find(({ options }) =>
    hasObject(options, defaultOption),
  )

  return variant ?? null
}

/**
 * Product with inventory data hook.
 */
export const useProductWithInventory = (
  locale: Locale,
  product?: SanityProductFragment,
): SanityProductFragment | undefined => {
  // Read product inventory
  const productInventoryRequest: ProductInventoryRequest = {
    localeHeader: locale,
    url: '/api/shopify/product-inventory',
    id: product?.productID,
  }
  const { data: productInventory } = useSWR<ProductInventory | undefined>(
    productInventoryRequest,
    async ({ localeHeader, url, id }: ProductInventoryRequest) => {
      if (!id) {
        return
      }

      const response = await axios.get<ProductInventory>(url, {
        params: {
          id,
        },
        headers: {
          'X-Locale': localeHeader,
        },
      })

      return response.data
    },
    {
      errorRetryCount: 3,
    },
  )

  return useMemo(() => {
    if (!product || !productInventory) {
      return product
    }

    const productVariants = product.variants ?? []

    return {
      ...product,
      inStock: productInventory.inStock,
      lowStock: productInventory.lowStock,
      variants: productVariants.map((productVariant) => {
        const productInventoryVariant = productInventory.variants.find(
          ({ id }) => id === productVariant.variantID,
        )

        if (!productInventoryVariant) {
          return productVariant
        }

        return {
          ...productVariant,
          ...productInventoryVariant,
        }
      }),
    }
  }, [product, productInventory])
}

/**
 * Active product variant hook.
 */
export const useActiveVariant = (product?: SanityProductFragment) => {
  const { isRouteChanging } = useContext(SiteContext)

  const defaultVariantId = useMemo(() => {
    if (!product) {
      return null
    }

    const defaultOption = getDefaultOption(
      product.options,
      product.optionSettings ?? [],
    )
    const firstVariant = product.variants?.[0] ?? null
    const defaultVariant =
      getVariantByDefaultOption(product.variants ?? [], defaultOption) ??
      firstVariant
    return defaultVariant?.variantID ?? null
  }, [product])

  const [currentParameters, setCurrentParameters] = useUrlParameters([
    {
      name: 'variant',
      value: defaultVariantId ? `${defaultVariantId}` : null,
    },
  ])

  // Manage URL parameters
  const previousParameters = usePrevious(currentParameters)
  const activeParameters = useMemo(() => {
    return isRouteChanging && previousParameters
      ? previousParameters
      : currentParameters
  }, [currentParameters, previousParameters, isRouteChanging])

  // Find active variant
  const variantIds = useMemo(() => {
    return product?.variants?.map(({ variantID }) => variantID) ?? []
  }, [product?.variants])

  const activeVariantId = useMemo(() => {
    const parameterVariant = activeParameters.find(
      ({ name }) => name === 'variant',
    )
    const parameterVariantValue = parseOptionalParameter<string>(
      parameterVariant?.value,
    )
    const parameterVariantId = parameterVariantValue
      ? Number(parameterVariantValue)
      : null

    return variantIds.some((id) => id == parameterVariantId)
      ? parameterVariantId
      : defaultVariantId
  }, [activeParameters, defaultVariantId, variantIds])

  const activeVariant = useMemo(() => {
    return product?.variants?.find(
      ({ variantID }) => variantID === activeVariantId,
    )
  }, [product?.variants, activeVariantId])

  // Handle variant change
  const updateProductPageUrl = useCallback(
    (variantId: number) => {
      const isValidVariant = variantIds.some((id) => id === variantId)

      setCurrentParameters([
        ...activeParameters.filter(({ name }) => name !== 'variant'),
        {
          name: 'variant',
          value: isValidVariant ? `${variantId}` : `${defaultVariantId}`,
        },
      ])
    },
    [activeParameters, defaultVariantId, setCurrentParameters, variantIds],
  )

  return [activeVariant, updateProductPageUrl] as const
}

/**
 * Finds variant from active options and new option value.
 */
export const getVariantFromOptions = (
  variants: SanityProductVariantFragment[],
  activeOptions: SanityProductVariantOption[],
  optionName: string,
  optionValue: string,
) => {
  const newOptions = activeOptions.map((activeOption) =>
    activeOption.name === optionName
      ? {
          ...activeOption,
          value: optionValue,
        }
      : activeOption,
  )

  // Find variant that matches all new options
  return variants.find(({ options }) =>
    options.every((variantOption) => hasObject(newOptions, variantOption)),
  )
}

/**
 * Finds fallback variant from new option value.
 */
export const getFallbackVariantFromOptions = (
  variants: SanityProductVariantFragment[],
  optionName: string,
  optionValue: string,
) =>
  variants.find((variant) =>
    variant.options.some(
      (variantOption) =>
        variantOption.name === optionName &&
        variantOption.value === optionValue,
    ),
  )

/**
 * Gets product key for lists of products in React.
 */
export const getProductKey = (product: SanityProductFragment) => {
  const variantKey =
    product.variants
      ?.map((variant) => variant.variantID)
      ?.filter(Boolean)
      ?.join('-') ?? 'defaultVariant'

  return `${product.productID}-${variantKey}`
}

/**
 * Gets expanded products where each product option that has gallery is a separate product.
 */
export const getExpandedProducts = (products: SanityProductFragment[]) => {
  const expandedProducts: ExpandedProduct[] = []

  products.forEach((product) => {
    product.galleryPhotos?.forEach((gallery) => {
      const variant = product.variants?.find((variant) => {
        const variantForOptions = variant.options.map(
          (option) => `${option.name}:${option.value}`,
        )

        return (
          gallery.forOption && variantForOptions.includes(gallery.forOption)
        )
      })

      expandedProducts.push({
        ...product,
        // Update price and compare price
        price: variant?.price ?? product.price,
        comparePrice: variant?.comparePrice ?? product.comparePrice,
        // Keep filters for the current option and for all options
        filters: product.filters?.filter(
          (filter) =>
            !filter.forOption || filter.forOption === gallery?.forOption,
        ),
        preferredOption: gallery?.forOption
          ? {
              name: gallery.forOption.split(':')[0],
              value: gallery.forOption.split(':')[1],
            }
          : undefined,
      })
    })
  })

  return expandedProducts
}

/**
 * Gets filtered products and product options which have a discount.
 */
export const getOnlyDiscountedProducts = (
  products: SanityProductFragment[],
) => {
  const discountedProducts: SanityProductFragment[] = []

  products.forEach((product) => {
    // Filter variants by compare price
    const variantsWithDiscount =
      product.variants?.filter((variant) => variant.comparePrice > 0) ?? []

    // Find option key-value pairs with discount
    const forOptionsWithDiscount: string[] = []
    variantsWithDiscount.forEach((variant) => {
      variant.options.forEach((option) => {
        const forOption = `${option.name}:${option.value}`

        if (!forOptionsWithDiscount.includes(forOption)) {
          forOptionsWithDiscount.push(forOption)
        }
      })
    })

    // Find gallery items, listing items, options and options settings by options which have a discount
    const galleryPhotosWithDiscount = product.galleryPhotos?.filter(
      (galleryPhoto) =>
        !galleryPhoto.forOption ||
        forOptionsWithDiscount.includes(galleryPhoto.forOption),
    )
    const listingPhotosWithDiscount = product.listingPhotos?.filter(
      (listingPhoto) =>
        !listingPhoto.forOption ||
        forOptionsWithDiscount.includes(listingPhoto.forOption),
    )
    const optionSettingsWithDiscount = product.optionSettings?.filter(
      (optionSetting) =>
        !optionSetting.forOption ||
        forOptionsWithDiscount.includes(optionSetting.forOption),
    )
    const optionsWithDiscount = product.options
      .map((option) => ({
        ...option,
        values: option.values.filter((value) => {
          const forOption = `${option.name}:${value}`

          return forOptionsWithDiscount.includes(forOption)
        }),
      }))
      .filter((option) => option.values.length > 0)

    if (variantsWithDiscount.length > 0) {
      discountedProducts.push({
        ...product,
        galleryPhotos: galleryPhotosWithDiscount,
        listingPhotos: listingPhotosWithDiscount,
        options: optionsWithDiscount,
        optionSettings: optionSettingsWithDiscount,
        variants: variantsWithDiscount,
      })
    }
  })

  return discountedProducts
}

/**
 * Sorts products in given order of product IDs.
 */
export const getSortedProducts = (
  products: SanityProductFragment[],
  productIds: string[],
) => {
  // Map product IDs to product indices in the sorted ID array
  const productIdIndexMap: Record<string, number> = {}

  productIds.forEach((productId, index) => {
    productIdIndexMap[productId] = index
  })

  return products.sort((product1, product2) => {
    const index1 = productIdIndexMap[product1._id]
    const index2 = productIdIndexMap[product2._id]

    if (typeof index1 === 'undefined' || typeof index2 === 'undefined') {
      return 0
    }

    return compareNumbers(index1, index2)
  })
}
