import { useContext } from 'react'

import { StringsContext } from '@lib/strings-context'

const CartEmpty = () => {
  const strings = useContext(StringsContext)

  return <p className="px-3 py-0.5">{strings.cartEmpty}</p>
}

export default CartEmpty
