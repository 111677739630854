import groq from 'groq'

import { imageFragment } from './image'
import { linkPageFragment } from './link'
import { muxVideoFragment, videoFragment } from './video'

const markDefFragment = groq`
  ...,
  _type == "link" => {
    page->{
      ${linkPageFragment}
    },
  },
`

export const simplePortableTextFragment = groq`
  ...,
  markDefs[] {
    ${markDefFragment}
  },
`

export const complexPortableTextFragment = groq`
  ...,
  markDefs[] {
    ${markDefFragment}
  },
  _type == "addToCartButton" => {
    productVariant->{
      variantID,
      inStock,
      price,
      comparePrice,
    },
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == 'lottieAnimation' => {
    "assetUrl": file.asset->url,
  },
  _type == "videoEmbed" => {
    layout,
    ${muxVideoFragment}
  },
  _type == "videoButton" => {
    thumbnail {
      ${imageFragment}
    },
    video {
      ${videoFragment}
    },
  },
`
