import axios from 'axios'

import { type SanityStrings } from '@data/sanity/queries/types/site'
import { parseShopifyCart } from '@lib/shopify/cart'
import { createShopifyCart } from '@lib/shopify/graphql/cart'
import { ShopifyClient } from '@lib/shopify/graphql/client'
import { ShopifyShop } from '@lib/shopify/graphql/shop'
import { type Locale } from '@lib/language'
import { getShopifyCartIdStorageKey } from '@lib/local-storage'
import { type Cart, type CartFormValues, type ValidateCartProps } from './types'
import { type ValidateVatIdResult } from '@pages/api/tax/validate-vat-id'

export const emptyCart: Cart = {
  id: '',
  lineItems: [],
  subTotal: 0,
  total: 0,
  webUrl: '',
  automaticDiscount: {
    title: '',
    amount: 0,
  },
  discountCodes: [],
}

/**
 * Validates cart form.
 */
export const validateCart = async (
  strings: SanityStrings,
  values: CartFormValues,
) => {
  const results: ValidateCartProps = {
    errors: {},
    vatIdCountryCode: null,
  }

  if (values.vatId) {
    const validationResult = await validateVatId(values.vatId)
    results.vatIdCountryCode = validationResult?.countryCode ?? null

    if (!validationResult?.isValid) {
      results.errors.vatId = !validationResult
        ? strings.cartVatIdError
        : strings.cartInvalidVatIdError
    }
  }

  return results
}

/**
 * Validates VAT ID using API route.
 */
export const validateVatId = async (vatId: CartFormValues['vatId']) => {
  try {
    const validationResult = await axios.get<ValidateVatIdResult>(
      '/api/tax/validate-vat-id',
      {
        params: { id: vatId },
        headers: { 'Content-Type': 'application/json' },
      },
    )

    return validationResult.data
  } catch (_) {
    return
  }
}

/**
 * Loads cart data from Shopify.
 */
export const loadCart = async (
  locale: Locale,
  shopifyStorefrontClient: ShopifyClient,
  shop: ShopifyShop,
): Promise<Cart | undefined> => {
  const shopCart = 'cart' in shop && shop.cart ? shop.cart : null

  if (shopCart) {
    const currentCart = await parseShopifyCart(locale, shopCart)

    if (currentCart) {
      return currentCart
    }
  }

  // Delete saved cart, if cart was not found
  const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
  localStorage.removeItem(shopifyCartIdStorageKey)

  // Create a new cart
  const createShopifyCartResponse = await createShopifyCart(
    shopifyStorefrontClient,
    {
      note: '',
    },
  )
  const createdCart = await parseShopifyCart(
    locale,
    createShopifyCartResponse.cart,
  )

  return createdCart
}
