import cx from 'classnames'
import { useContext } from 'react'

import { getPrice } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartTotalProps {
  price: number
  className?: string
}

const CartTotal = ({ price, className }: CartTotalProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx(
        'flex items-center justify-between px-3 py-3.5 text-sm uppercase',
        className,
      )}
    >
      <span>{strings.cartTotal}</span>
      <span>
        {currency}
        {getPrice(price, taxRate)}
      </span>
    </div>
  )
}

export default CartTotal
