import { useContext } from 'react'
import cx from 'classnames'

import { getPrice } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import Chip from '@components/chip'

interface CartDiscountProps {
  title: string
  amount: number
  quantity?: number
  className?: string
}

const CartDiscount = ({
  title,
  amount,
  quantity = 1,
  className,
}: CartDiscountProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx(
        'flex items-center justify-between px-3 py-2 text-xs',
        className,
      )}
    >
      <div className="flex items-center gap-3 uppercase">
        {strings.cartDiscount}
        <Chip label={title} />
        {quantity > 1 && `x${quantity}`}
      </div>

      <span className="uppercase">
        -{currency}
        {getPrice(amount * quantity, taxRate)}
      </span>
    </div>
  )
}

export default CartDiscount
