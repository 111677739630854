import cx from 'classnames'
import { useContext, useMemo } from 'react'

import { CartContext } from '@lib/cart/context'
import { LineItem } from '@lib/cart/types'
import { getProductListingThumbnails } from '@lib/product-images'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import SimpleLink from '@components/simple-link'
import Photo from '@components/photo'
import ProductPrice from '@blocks/product/product-price'
import ProductCounter from '@blocks/product/product-counter'
import ProductThumbnail from '@blocks/product/product-thumbnail'

interface CartItemProps {
  item: LineItem
  className?: string
}

const CartItem = ({ item, className }: CartItemProps) => {
  const strings = useContext(StringsContext)
  const { toggleCart, updateCartItem, removeItemFromCart } =
    useContext(CartContext)

  const thumbnailImage = useMemo(() => {
    const listingPhotos = !!item.product.inheritCartPhotos
      ? item.product.listingPhotos
      : item.product.cartPhotos

    return getProductListingThumbnails(
      item.product.galleryPhotos ?? [],
      listingPhotos ?? [],
      item.options,
    )
  }, [item.product, item.options])

  const productUrl = item.product.slug.current
    ? getLinkPageUrl('product', item.product.slug.current, {
        variant: `${item.variantID}`,
      })
    : null

  return (
    <div className={cx('grid grid-cols-[auto,1fr] gap-3', className)}>
      {!!thumbnailImage && (
        <Photo
          image={thumbnailImage}
          sizes="(min-width: 768px) 400px, 35vw"
          className="max-w-20 xs:max-w-24"
        />
      )}

      <div className="flex flex-col">
        <div className="grid grid-cols-2 min-h-full gap-x-3 gap-y-6 pb-3">
          <div>
            <h3 className="text-xs leading-normal">
              {productUrl ? (
                <SimpleLink
                  href={productUrl}
                  onClick={() => toggleCart(false)}
                  onBeforeInput={() => toggleCart(false)}
                  tabIndex={0}
                  role="link"
                >
                  {item.product.title}
                </SimpleLink>
              ) : (
                item.product.title
              )}
            </h3>

            {item.options.map((option) => (
              <p className="text-xs uppercase" key={option.name}>
                {option.name}: {option.value}
              </p>
            ))}
          </div>

          <ProductPrice
            price={item.price}
            className="text-xs leading-normal self-start justify-self-end"
          />

          <ProductCounter
            id={`${item.variantID}`}
            defaultCount={item.quantity}
            onUpdate={(quantity) => updateCartItem(item.lineId, quantity)}
            className="justify-self-start"
            // TODO: Set max
          />

          <button
            onClick={() => removeItemFromCart(item.lineId)}
            className={cx(
              'text-xs text-gray-dark uppercase underline hover:no-underline self-center justify-self-end',
            )}
          >
            {strings.buttonRemove}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CartItem
