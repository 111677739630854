import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  type SanityProductCartPhoto,
  type SanityProductGalleryPhoto,
  type SanityProductListingPhoto,
  type SanityProductVariantFragment,
  type SanityProductVariantOption,
} from '@data/sanity/queries/types/product'
import { hasObject, isNumeric } from './helpers'

/**
 * Gets index from filename (e.g., "title_option_1.jpg" => "1").
 */
export const getIndexFormFilename = (filename: string) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  const filenameIndexMap: Record<string, number> = {
    F: 1,
    B: 2,
  }

  const filenameWithoutExtension = filename.split('.').slice(0, -1).join('.')
  const filenameParts = filenameWithoutExtension.split('_')
  // Remove UUID from filename, if present
  const filteredFilenameParts = filenameParts.filter(
    (filenamePart) => !uuidRegex.test(filenamePart),
  )
  const index = filteredFilenameParts.splice(-1, 1)[0]

  if (index && isNumeric(index)) {
    return Number(index)
  }

  if (index && filenameIndexMap[index]) {
    return filenameIndexMap[index]
  }

  return null
}

/**
 * Gets product gallery photos for selected variant.
 */
export const getProductGalleryPhotos = (
  photosets: SanityProductGalleryPhoto[],
  variant?: SanityProductVariantFragment,
): SanityImageFragment[] => {
  const variantPhotoset = photosets.find((photoset) => {
    const option = !!photoset.forOption
      ? {
          name: photoset.forOption.split(':')[0],
          value: photoset.forOption.split(':')[1],
        }
      : {}

    return option.value && variant && hasObject(variant.options, option)
  })

  if (variantPhotoset?.photos && variantPhotoset.photos.length > 0) {
    return variantPhotoset.photos
  }

  return photosets.find((photoset) => !photoset.forOption)?.photos ?? []
}

/**
 * Gets product listing thumbnails for selected variant using gallery photos.
 */
export const getProductListingThumbnails = (
  galleryPhotos: SanityProductGalleryPhoto[],
  listingPhotos: SanityProductListingPhoto[] | SanityProductCartPhoto[],
  options: SanityProductVariantOption[],
) => {
  const defaultListingPhoto = listingPhotos?.find(
    (listingPhoto) =>
      typeof listingPhoto === 'string' || !listingPhoto.forOption,
  )
  const variantListingPhoto = listingPhotos?.find((listingPhoto) => {
    if (!listingPhoto.forOption) {
      return false
    }

    const option: Partial<SanityProductVariantOption> = {
      name: listingPhoto.forOption.split(':')[0],
      value: listingPhoto.forOption.split(':')[1],
    }
    return option.value && hasObject(options, option)
  })
  const listingPhoto = variantListingPhoto ?? defaultListingPhoto

  const gallery = galleryPhotos.find((gallery) =>
    listingPhoto?.forOption
      ? gallery.forOption === listingPhoto?.forOption
      : !gallery.forOption,
  )
  const photos = gallery?.photos ?? []

  return photos.find((photo) => photo.asset._id === listingPhoto?.galleryImage)
}

/**
 * Find listing thumbnail in gallery photos.
 */
export const getProductListingThumbnail = (
  galleryPhotos: SanityProductGalleryPhoto[],
  forOption?: string,
  assetId?: string,
) => {
  if (!assetId) {
    return
  }

  // Get the default gallery or the gallery for given option
  const gallery = galleryPhotos.find((gallery) => {
    if (!forOption) {
      return !gallery.forOption
    }

    return gallery.forOption === forOption
  })

  return gallery?.photos?.find((photo) => photo.asset._id === assetId)
}
