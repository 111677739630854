import cx from 'classnames'
import { useContext } from 'react'

import { getPrice } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartSubtotalProps {
  price: number
  className?: string
}

const CartSubtotal = ({ price, className }: CartSubtotalProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx(
        'flex items-center justify-between px-3 py-3.5 text-xs uppercase',
        className,
      )}
    >
      <span>{strings.cartSubtotal}</span>
      <span className="uppercase">
        {currency}
        {getPrice(price, taxRate)}
      </span>
    </div>
  )
}

export default CartSubtotal
