import cx from 'classnames'
import {
  type ChangeEvent,
  type FocusEvent,
  type TextareaHTMLAttributes,
  useState,
} from 'react'

import { type CustomFormRegister } from './input-field'

interface TextAreaProps {
  formRegister: CustomFormRegister
  errorMessage?: string
  label?: string
  isMinimal?: boolean
  isUnstyled?: boolean
  rows?: number
  inputClassName?: string
}

const TextArea = ({
  id,
  value,
  defaultValue = '',
  autoComplete,
  placeholder,
  label,
  isMinimal,
  isUnstyled,
  errorMessage,
  formRegister,
  rows,
  className,
  inputClassName,
}: TextAreaProps & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const isManaged = typeof value !== 'undefined'
  const [currentValue, setCurrentValue] = useState(defaultValue)

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    formRegister.onBlur(event)
    setCurrentValue(event.target.value)
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    formRegister.onChange(event)
    setCurrentValue(event.target.value)
  }

  if (isUnstyled) {
    return (
      <textarea
        id={id}
        inputMode="text"
        autoComplete={autoComplete}
        ref={formRegister.ref}
        name={formRegister.name}
        rows={rows}
        value={isManaged ? value : currentValue}
        placeholder={placeholder}
        onBlur={(event) => handleBlur(event)}
        onChange={(event) => handleChange(event)}
        className={cx(inputClassName, className)}
      />
    )
  }

  return (
    <div className={cx('grid', className)}>
      <div className="flex flex-col gap-y-1 relative text-left">
        {label && (
          <label htmlFor={id} className="text-xs uppercase">
            {label}
          </label>
        )}

        <textarea
          id={id}
          inputMode="text"
          autoComplete={autoComplete}
          ref={formRegister.ref}
          name={formRegister.name}
          rows={rows}
          value={isManaged ? value : currentValue}
          placeholder={placeholder}
          onBlur={(event: FocusEvent<HTMLTextAreaElement>) => {
            formRegister.onBlur(event)
            setCurrentValue(event.target.value)
          }}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            formRegister.onChange(event)
            setCurrentValue(event.target.value)
          }}
          className={cx(
            'relative appearance-none w-full h-full py-4 text-base leading-none',
            'bg-input-bg text-input-text',
            {
              'border text-sm py-4 px-3': !isMinimal,
              'border-b text-xs py-3': isMinimal,
              'border-input-border': !errorMessage && !isMinimal,
              'border-input-minimal-border': !errorMessage && isMinimal,
              'border-error': errorMessage,
            },
            inputClassName,
          )}
        >
          {value}
        </textarea>

        {errorMessage && (
          <span role="alert" className="text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default TextArea
