import cx from 'classnames'
import { useContext, type ReactNode } from 'react'

import { useCartItemCount } from '@lib/cart/hooks'
import { CartContext } from '@lib/cart/context'

import Icon from '@components/icon'

interface CartToggleProps {
  hasCartCount?: boolean
  hasCartIcon?: boolean
  className?: string
  children?: ReactNode
}

const CartToggle = ({
  hasCartCount,
  hasCartIcon,
  className,
  children,
}: CartToggleProps) => {
  const { toggleCart } = useContext(CartContext)
  const cartCount = useCartItemCount()

  return (
    <button
      onClick={() => toggleCart()}
      className={cx(
        'relative appearance-none no-underline cursor-pointer font-inherit inline-flex items-center gap-x-2 transition-opacity hover:opacity-60',
        className,
      )}
    >
      {hasCartIcon && (
        <span className=" lg:hidden">
          <Icon
            id="cart"
            name="Cart"
            className={cx('text-current text-2xl', {
              relative: hasCartCount,
            })}
          />

          {hasCartCount && (
            <span className="absolute -top-1 -right-1 px-1 py-0.5 text-xxs bg-accent text-black border border-black rounded-full">
              {cartCount}
            </span>
          )}
        </span>
      )}

      <span className={cx({ 'hidden lg:inline': hasCartIcon })}>
        {children} {hasCartCount && `(${cartCount})`}
      </span>
    </button>
  )
}

export default CartToggle
