import cx from 'classnames'
import { type ReactNode } from 'react'

interface ChipProps {
  label: string
  className?: string
}

const Chip = ({ className, label }: ChipProps) => (
  <span
    className={cx(
      'bg-accent text-pageText text-xxs leading-snug uppercase py-1.5 px-2',
      className,
    )}
  >
    {label}
  </span>
)

export default Chip
