import { type ClientBrowserParameters } from '@shopify/hydrogen-react'
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { type CurrencyCode } from '@data/shopify/storefront/types'
import {
  type AnalyticsEvent,
  AnalyticsEventName,
  AnalyticsEventType,
  getAnalyticsEvents,
  triggerGoogleTagManagerEvent,
  triggerShopifyEvent,
} from './analytics'
import { CartContext } from './cart/context'
import { LanguageContext } from './language-context'
import { ShopContext } from './shop-context'
import { getShopifyDomain } from './shopify/client'
import { SiteContext } from './site-context'

interface AnalyticsContextProps {
  triggerAnalyticsEvent: (
    eventName: AnalyticsEventName,
    payload?: Record<string, unknown>,
  ) => void
}

interface AnalyticsProviderProps {
  children: ReactNode
}

export const AnalyticsContext = createContext<AnalyticsContextProps>({
  triggerAnalyticsEvent: () => null,
})

/**
 * Finds an event that is ready to be processed.
 */
const getReadyEvent = (
  events: AnalyticsEvent[],
  shopId?: string,
  currencyCode?: CurrencyCode,
  cartId?: string,
) =>
  events.find((event) => {
    if (event.type === AnalyticsEventType.GoogleTagManager) {
      return true
    }

    if (event.type === AnalyticsEventType.Shopify) {
      if (!shopId || !currencyCode) {
        return false
      }

      if (event.name === AnalyticsEventName.AddToCart && !cartId) {
        return false
      }

      return true
    }
  })

export const AnalyticsContextProvider = ({
  children,
}: AnalyticsProviderProps) => {
  const { settings } = useContext(SiteContext)
  const { cart } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { shopId, currencyCode } = useContext(ShopContext)

  const [eventQueue, setEventQueue] = useState<AnalyticsEvent[]>([])

  const pushAnalyticsEvent = useCallback(
    (analyticsEvent: AnalyticsEvent) => {
      setEventQueue((currentEventQueue) => [
        ...currentEventQueue,
        analyticsEvent,
      ])
    },
    [setEventQueue],
  )

  // Process event queue when events are added or other parameters change
  useEffect(() => {
    const event = getReadyEvent(eventQueue, shopId, currencyCode, cart?.id)

    if (!event) {
      return
    }

    // Remove event from queue
    setEventQueue((currentEventQueue) =>
      currentEventQueue.filter((eventItem) => eventItem.id !== event.id),
    )

    if (event.type === AnalyticsEventType.GoogleTagManager) {
      triggerGoogleTagManagerEvent(event.name, event.payload)
    }

    if (event.type === AnalyticsEventType.Shopify) {
      triggerShopifyEvent(
        event.name,
        event.payload as ClientBrowserParameters,
        shopId,
        currencyCode,
        cart?.id,
        getShopifyDomain(locale),
      )
    }
  }, [cart, currencyCode, eventQueue, locale, setEventQueue, shopId])

  const triggerAnalyticsEvent = useCallback(
    (eventName: AnalyticsEventName, payload?: Record<string, unknown>) => {
      const events = getAnalyticsEvents(eventName, payload ?? {}, {
        googleEvents: !!settings?.googleEvents,
        shopifyEvents: !!settings?.shopifyEvents,
      })

      events.forEach((event) => {
        pushAnalyticsEvent(event)
      })
    },
    [pushAnalyticsEvent, settings],
  )

  return (
    <AnalyticsContext.Provider
      value={{
        triggerAnalyticsEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
