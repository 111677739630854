import {
  type CartFragmentFragment,
  type GetShopAndCartQuery,
  type GetShopAndCartQueryVariables,
  type GetShopQuery,
  type ShopFragmentFragment,
} from '@data/shopify/storefront/types'
import {
  GET_SHOP,
  GET_SHOP_AND_CART,
} from '@data/shopify/storefront/queries/shop'
import { type ShopifyClient } from './client'

export interface ShopifyShop {
  shop?: ShopFragmentFragment
  cart?: CartFragmentFragment
}

/**
 * Gets Shopify shop information.
 */
export const getShopifyShop = async (
  shopifyStorefrontClient: ShopifyClient
): Promise<ShopifyShop | undefined> => {
  try {
    const getShopResult = await shopifyStorefrontClient.query<GetShopQuery>({
      query: GET_SHOP,
    })

    if (!getShopResult.data) {
      return
    }

    const shopFragment = getShopResult.data.shop as
      | ShopFragmentFragment
      | undefined

    return { shop: shopFragment }
  } catch (error) {
    console.log(error)
  }
}

/**
 * Gets Shopify shop information and cart.
 */
export const getShopifyShopAndCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string | null
): Promise<ShopifyShop | undefined> => {
  if (!cartId) {
    return await getShopifyShop(shopifyStorefrontClient)
  }

  try {
    const getShopResult = await shopifyStorefrontClient.query<
      GetShopAndCartQuery,
      GetShopAndCartQueryVariables
    >({
      query: GET_SHOP_AND_CART,
      variables: { cartId },
    })

    if (!getShopResult.data) {
      return
    }

    const shopFragment = getShopResult.data.shop as
      | ShopFragmentFragment
      | undefined
    const cartFragment = getShopResult.data.cart as
      | CartFragmentFragment
      | undefined

    return { shop: shopFragment, cart: cartFragment }
  } catch (error) {
    console.log(error)
  }
}
