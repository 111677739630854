import { type ReactNode, createContext, useContext, useState } from 'react'

import {
  type SanityProductFragment,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { LanguageContext } from './language-context'
import { useActiveVariant, useProductWithInventory } from './product'

interface ProductContextProps {
  product?: SanityProductFragment
  setProduct: (product: SanityProductFragment) => void
  activeVariant?: SanityProductVariantFragment
  onVariantChange?: (variantId: number) => void
}

interface StringsContextProviderProps {
  children: ReactNode
}

export const ProductContext = createContext<ProductContextProps>({
  setProduct: () => null,
})

export const ProductContextProvider = ({
  children,
}: StringsContextProviderProps) => {
  const { locale } = useContext(LanguageContext)

  const [product, setProduct] = useState<SanityProductFragment>()

  // Get active product and variant
  const activeProduct = useProductWithInventory(locale, product)
  const [activeVariant, onVariantChange] = useActiveVariant(activeProduct)

  return (
    <ProductContext.Provider
      value={{
        product: activeProduct,
        setProduct,
        activeVariant,
        onVariantChange,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
