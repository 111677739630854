import {
  type ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { useUrlParameters } from './hooks'
import { pageRoutes } from './routes'

interface ProductSearchContextSearchDrawer {
  isOpen: boolean
}

interface ProductSearchContextProps {
  query: string
  updateQuery: (newQuery: string) => void
  productSearchResultsLoaded: boolean
  setProductSearchResultsLoaded: (
    newProductSearchResultsLoaded: boolean,
  ) => void
  productSearchDrawer: ProductSearchContextSearchDrawer
  toggleProductSearchDrawer: (isOpen: boolean) => void
}

interface ProductSearchContextProviderProps {
  children: ReactNode
}

export const ProductSearchContext = createContext<ProductSearchContextProps>({
  query: '',
  updateQuery: () => null,
  productSearchResultsLoaded: false,
  setProductSearchResultsLoaded: () => null,
  productSearchDrawer: {
    isOpen: false,
  },
  toggleProductSearchDrawer: () => null,
})

export const ProductSearchContextProvider = ({
  children,
}: ProductSearchContextProviderProps) => {
  const router = useRouter()

  const [productSearchResultsLoaded, setProductSearchResultsLoaded] =
    useState(false)
  const [productSearchDrawer, setSearchDrawer] =
    useState<ProductSearchContextSearchDrawer>({
      isOpen: false,
    })

  // Get initial search query on page load
  const [urlParameters, updateUrlParameters] = useUrlParameters([
    {
      name: 'query',
    },
  ])
  const initialQuery =
    urlParameters.find(({ name }) => name === 'query')?.value || ''

  // Initialize query state with the initial query
  const [query, setQuery] = useState<string>(initialQuery)

  const updateQuery = useCallback(
    (newQuery: string) => {
      const isSearchPage =
        router.pathname.substring(1) === pageRoutes.shopSearchPage.join('/')

      if (isSearchPage) {
        updateUrlParameters([
          {
            name: 'query',
            value: newQuery,
          },
        ])
      }

      setQuery(newQuery)
    },
    [router.pathname, updateUrlParameters],
  )

  const toggleProductSearchDrawer = useCallback((isOpen: boolean) => {
    setSearchDrawer({
      isOpen,
    })
  }, [])

  return (
    <ProductSearchContext.Provider
      value={{
        query,
        updateQuery,
        productSearchResultsLoaded,
        setProductSearchResultsLoaded,
        productSearchDrawer,
        toggleProductSearchDrawer,
      }}
    >
      {children}
    </ProductSearchContext.Provider>
  )
}
