import { gql } from '@apollo/client'

import { CART_FRAGMENT, SHOP_FRAGMENT } from '../fragments'

export const GET_SHOP = gql`
  ${SHOP_FRAGMENT}

  query getShop {
    shop {
      ...ShopFragment
    }
  }
`

export const GET_SHOP_AND_CART = gql`
  ${SHOP_FRAGMENT}
  ${CART_FRAGMENT}

  query getShopAndCart($cartId: ID!) {
    shop {
      ...ShopFragment
    }
    cart(id: $cartId) {
      ...CartFragment
    }
  }
`
