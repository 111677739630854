import { type ReactNode, createContext, useCallback, useContext } from 'react'
import { useRouter } from 'next/router'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { type Locale, defaultLocale } from './language'
import { getLinkPageUrl } from './routes'
import { SiteContext } from './site-context'

interface LanguageContextProps {
  locale: Locale
  publicLocales: Locale[]
  changeLanguage: (newLocale: Locale) => void
}

const initialContext: LanguageContextProps = {
  locale: defaultLocale,
  publicLocales: [],
  changeLanguage: () => null,
}

export const LanguageContext =
  createContext<LanguageContextProps>(initialContext)

interface LanguageContextProviderProps {
  locale: Locale
  site: SanitySiteFragment
  children: ReactNode
}

export const LanguageContextProvider = ({
  locale,
  site,
  children,
}: LanguageContextProviderProps) => {
  const router = useRouter()
  const { toggleMegaNavigation, toggleMobileMenu } = useContext(SiteContext)

  // Handle language change
  const changeLanguage = useCallback(
    (newLocale: Locale) => {
      toggleMegaNavigation(false)
      toggleMobileMenu(false)

      if (locale !== newLocale) {
        const url = getLinkPageUrl('homePage')
        router.push(url, url, {
          locale: newLocale,
        })
      }
    },
    [locale, router, toggleMegaNavigation, toggleMobileMenu]
  )

  return (
    <LanguageContext.Provider
      value={{
        locale,
        publicLocales: site.publicLocales as Locale[],
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
